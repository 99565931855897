.cookie-banner {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10000;
  padding: 32px;
  font-size: 0.790125rem; // 12.642
  line-height: 1.26562253; // 16px
  letter-spacing: 0.6px;
  color: #202730;
  background-color: #fff;
  max-width: 480px;
  max-height: calc(100% - 6em);
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 20px 0 rgba(0, 0, 0, 0.03);

  &__title {
    margin-bottom: 0.75rem; // 12px

    a {
      @apply border-b;

      &:hover {
        @apply border-none;
      }
    }
  }

  &__settings {
    overflow: hidden;
    margin-bottom: 1em;
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cookie-button {
      @extend .button;
      margin: 0;
      // @extend .button-small;
      // @extend .button-green;
      height: auto;
      text-transform: capitalize;

      &:hover {
        cursor: pointer;
      }
    }

    .cookie-text-link {
      // @apply text-textPrimaryColor;
      line-height: 2.8em;
      margin-right: 1em;
    }
  }

  &__cookie-details {
    &:not(last-child) {
      margin-bottom: 1em;
    }
  }

  &__details {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }

  &__cookie-name {}

  .cookie-details__row {
    border-top: 1px solid #cccccc;
    padding-top: 1em;
    margin-bottom: 1em;
    display: none;
  }

  &.full-width {
    .cookie-details__row {
      display: block !important;
    }

    .cookie-banner__cookie-details {
      margin-bottom: 24px;
    }

    .cookie__field {
      display: block !important;
    }

    #elc-detail-link {
      display: none;
    }

    #elc-accept-link {
      display: none;
    }
  }

  &.small {

    // width: 30em;
    #elc-save-link {
      display: none;
    }

    .hide-when-small {
      display: none;
    }

    #elc-hide-detail-link {
      display: none;
    }
  }

  .cookie {
    &__field {
      margin-bottom: 8px;
      display: none;
    }
  }

  &.elc-hidden {
    display: none;

    #elc-hide-detail-link {
      display: none;
    }
  }

  #elc-cookie-tab {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0.2em 1em;
    background-color: #0e3a5e;
    font-size: 0.8em;
    z-index: 10000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }

  #elc-cookie-tab a {
    color: #ffffff;
  }

  .checkbox {
    padding-left: 16px;
  }

  .checkbox::before {
    top: -1px;
  }

  input[type="checkbox"]:checked+.checkbox::after {
    top: 1px;
  }
}