input[type='checkbox'] {
  display: none;
}

.checkbox {
  position: relative;
  padding-left: 32px;
  display: block;
}

.checkbox::before {
  content: "";
  height: 16px;
  width: 16px;
  position: absolute;
  left: 0;
  top: 4px;
}

.checkbox-black::before {
  border: 1px solid black;
}

.checkbox-white::before {
  border: 1px solid white;
}


input[type="checkbox"]:checked + .checkbox::after {
  content: '';
  display: block;
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 1px;
  top: 7px;
  z-index: 10;
}

input[type="checkbox"]:checked + .checkbox-black::before {
  background-color:black;
}

input[type="checkbox"][disabled]:checked + .checkbox-black::before {
  background-color:darkgrey;
  border: 1px solid darkgrey;
}

input[type="checkbox"]:checked + .checkbox-black::after {
  background-image: url('../../img/checkmark-white.svg');
}

input[type="checkbox"]:checked + .checkbox-white::before {
  background-color:white;
}

input[type="checkbox"]:checked + .checkbox-white::after {
  background-image: url('../../img/checkmark-black.svg');
}

