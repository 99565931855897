.infinite-scroll-container {
	.article-feed {
		opacity: 1;
	}
	.loader {
		display: none;
	}
	&.loading {
		.article-feed {
			opacity: 0;
		}
		.loader {
			display: block;
		}
	}
}


.product-card-placeholder {
  &__image {
    animation: shine 2.5s linear infinite;
    background: linear-gradient(to right, #d8d8d8 8%, #f9f9f9 16%, #d8d8d8 30%);
    background-size: 800px auto;
  }
  &__title {
    animation: shine 2.5s linear infinite;
    background: linear-gradient(to right, #d8d8d8 8%, #f9f9f9 16%, #d8d8d8 30%);
    background-size: 800px auto;
  }
  &__type {
    animation: shine 2.5s linear infinite;
    background: linear-gradient(to right, #d8d8d8 8%, #f9f9f9 16%, #d8d8d8 30%);
    background-size: 800px auto;
  }
  &__price {
    animation: shine 2.5s linear infinite;
    background: linear-gradient(to right, #d8d8d8 8%, #f9f9f9 16%, #d8d8d8 30%);
    background-size: 800px auto;
  }
}

@keyframes shine {
  0% {
    background-position: -800px;
  }
  100% {
    background-position: 800px;
  }
}