.move {
	svg {
		transition: transform 200ms;
	}

	&:hover {
   transition: transform 200ms;
   svg {
    transform: translateX(20px);
  }
}
}

.move-down {
  svg {
    transition: transform 200ms;
  }

  &:hover {
   transition: transform 200ms;
   svg {
    transform: translateY(20px);
  }
}
}






/*FRONTPAGE HERO ANIMATION*/

@media only screen and (min-width: 768px) {
  .left-top {
    animation: animateLT 1200ms ease-in forwards;
  }

  @keyframes animateLT {
    0% {
      transform: scale(2,1.5);
      transform-origin: top left;
    }
    25% {
      transform: scale(2,1.5);
      transform-origin: top left;
    }
    60% {
      transform: scale(1, 1);
      transform-origin: top left;
    }
  }

  .left-bottom {
    animation: animateLB 1200ms ease-in forwards;
  }

  @keyframes animateLB {
    0% {
      transform: translateY(100%) scaleX(2);
      transform-origin: left;
    }
    25% {
      transform: translateY(0) scaleX(2);
      transform-origin: left;
    }
    60% {
      transform: translateY(0) scaleX(1);
      transform-origin: left;
    }
  }


  .right-bottom {
    animation: animateRB 1200ms ease-in forwards;
  }

  @keyframes animateRB {
    0% {
      transform: translate(100%, -120px);
      transform-origin: bottom left;
    }
    25% {
      transform: translate(100%, -120px);
      transform-origin: bottom left;
    }
    60% {
      transform: translate(0, -120px);
      transform-origin: bottom left;
    }
    100% {
      transform: translate(0,0);
      transform-origin: bottom left;
    }
  }

  .landing-text {
   animation: fadeBelow 1600ms ease-in forwards;
 }

 @keyframes fadeBelow {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  80% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.landing-search {
	animation: fadeIn 1600ms ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
}

@media only screen and (min-width: 1024px) {

  .right-bottom {
    animation: animateRB 1200ms ease-in forwards;
  }

  @keyframes animateRB {
    0% {
      transform: translate(100%, -168px);
      transform-origin: bottom left;
    }
    25% {
      transform: translate(100%, -168px);
      transform-origin: bottom left;
    }
    60% {
      transform: translate(0, -168px);
      transform-origin: bottom left;
    }
    100% {
      transform: translate(0,0);
      transform-origin: bottom left;
    }
  }
}


.lazyload-image-effect {
  overflow: hidden;
  img {
    transition: filter 0.2s linear;
    filter: blur(5px);
    &.lazyloaded {
      filter: blur(0px);
    }
  }
}

img.lazyload:not([src]) {
  visibility: hidden;
}