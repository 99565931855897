
/**
 * app.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */

@tailwind base;

/**
 * This injects any component classes registered by plugins.
 *
 */
@tailwind components;

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

@import 'components/articleLanding';
@import 'components/buttons';
@import 'components/errors';
@import 'components/loader';
@import 'components/wysiwyg';
@import 'components/header';
@import 'components/accordion';
@import 'components/articleCard';
@import 'components/peopleSection';
@import 'components/checkbox';
@import 'components/forms';
@import 'components/cookieBanner';
@import 'components/flickity';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */
 @tailwind utilities;


@import 'utils/fonts';
@import 'utils/globals';
@import 'utils/helpers';
@import 'utils/typography';
@import 'utils/animations';
@import 'utils/print';

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
 @tailwind screens;