@font-face {
	font-family: 'FoundersGroteskLight';
	src: url('../../fonts/FoundersGroteskTextWeb-Light.eot');
	src: url('../../fonts/FoundersGroteskTextWeb-Light.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/FoundersGroteskTextWeb-Light.woff2') format('woff2'),
	url('../../fonts/FoundersGroteskTextWeb-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskLightItalic';
  src: url('../../fonts/founders-grotesk-text-web-light-italic.eot');
  src: url('../../fonts/founders-grotesk-text-web-light-italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/founders-grotesk-text-web-light-italic.woff2') format('woff2'),
  url('../../fonts/founders-grotesk-text-web-light-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskMedium';
  src: url('../../fonts/founders-grotesk-text-web-medium.eot');
  src: url('../../fonts/founders-grotesk-text-web-medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/founders-grotesk-text-web-medium.woff2') format('woff2'),
  url('../../fonts/founders-grotesk-text-web-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskMediumItalic';
  src: url('../../fonts/founders-grotesk-text-web-medium-italic.eot');
  src: url('../../fonts/founders-grotesk-text-web-medium-italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/founders-grotesk-text-web-medium-italic.woff2') format('woff2'),
  url('../../fonts/founders-grotesk-text-web-medium-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}