.logo {
	fill: #231F20;
}

.hamburger {
	align-self: center;
	margin: 0;
	cursor: pointer;
}

.line {
	display: block;
	width: 30px;
	height: 2px;
	background-color: black;
	transition: transform 200ms;
	&:not(:last-of-type) {
		margin-bottom: 6px;
	}
}

.line-white {
	display: block;
	width: 30px;
	height: 2px;
	background-color: white;
	transition: transform 200ms;
	&:not(:last-of-type) {
		margin-bottom: 6px;
	}
}

.show-menu {
	display: none;
}

.slide-menu {
	top:0;
	right: 0;
	margin-right: -100%;
	z-index: 100;
	transition: transform 300ms cubic-bezier(0.77, 0, 0.175, 1);
}

.site {
	transition: transform 300ms cubic-bezier(0.77, 0, 0.175, 1);
}

.show-menu:checked ~ .site {
	transform: translateX(-100%);
}

.show-menu:checked ~ .slide-menu {
	transform: translate(-100%)
}

@media only screen and (min-width: 768px) {
	.slide-menu {
		margin-right: -60%;
	}
	.show-menu:checked ~ .site {
		transform: translateX(-60%);
	}
}

@media only screen and (min-width: 1024px) {
	.slide-menu {
		margin-right: -50%;
	}
	.show-menu:checked ~ .site {
		transform: translateX(-50%);
	}
}

@media only screen and (min-width: 1280px) {
	.slide-menu {
		margin-right: -33.3333%;
	}
	.show-menu:checked ~ .site {
		transform: translateX(-33.3333%);
	}
}


.show-menu:checked ~ .site .main-nav-outer #line-1 {
	transform: rotate(45deg) translateY(7px);
	margin-bottom: 0;
}

.show-menu:checked ~ .site .main-nav-outer #line-2 {
	opacity: 0;
}

.show-menu:checked ~ .site .main-nav-outer #line-3 {
	transform: rotate(-45deg) translateY(-8px);
	margin-bottom: 0;
}


.show-menu:checked ~ .slide-menu #line-w-1 {
	margin-bottom: 0;
	transform: rotate(45deg) translateY(7px);
}

.show-menu:checked ~ .slide-menu #line-w-2 {
	opacity: 0;
}

.show-menu:checked ~ .slide-menu #line-w-3 {
	margin-bottom: 0;
	transform: rotate(-45deg) translateY(-8px);
}