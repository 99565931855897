@media print {
	header, footer {
		display: none;
	}

	.ps-intro figure {
		width: 150px;
		height: 150px;
		margin-left: 30px;
	}

	.article-section, .next-article {
		display: none;
	}
}