.error-template {
  .logo {
    fill: white;
  }
  .search-icon {
    stroke: white;
  }
  .desktop-nav {
    color: white;
  }
  .desktop-link {
    opacity: 100;
  }
  .line {
    border-bottom: 2px solid white;
  }
}