.people-list {
	.list-container {
		display:block !important;
		margin-bottom: 60px;
	}
	
	.people-card-outer {
		width: 100%;
		margin:0;
		padding: 24px 0;
		border-bottom: 1px solid black;
		display: block;

		figure {
			display: none;
		}

		div {
			margin-bottom: 4px;

			&:nth-of-type(2) {
				margin-bottom: 8px;
			};
		}

		&:first-of-type {
			border-top: 1px solid black;
		};
	}	
}


@media only screen and (min-width: 768px) {
	.people-list {
		.list-container {
			padding: 0 16px;
		}

	
	.people-card-outer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin:0;
		padding: 24px 0 26px 0;
		border-bottom: 1px solid black;

		div {
			margin-bottom: 0;
			width: 25%;

			&:nth-of-type(2) {
				margin-bottom: 0;
			};
		}
	}	
}
}


input[type="radio"] {
	display:none;
}

.radio-btn {
	position: relative;
	padding-left: 18px;
	cursor: pointer;
}

.radio-btn::before {
	content: "";
	display: block;
	position: absolute;
	width: 12px;
	height: 12px;
	bottom: 3px;
	left: 0;
	border: 1px solid black;
	border-radius: 50%;
	transition: background-color 200ms;
}

input[type="radio"]:checked + .radio-btn::before {
    background-color: black;
}