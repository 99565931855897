.overlay {
  figure {
    &:after {
      content: "";
      opacity: 0.5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 49;
    }
    img {
      opacity: 1;
      filter: saturate(0) contrast(1.1);
    }
  }
  &-warm-gray {
    figure {
      &:after {
        background-color: #EEE8E8;
      }
    }
  }
  &-pink {
    figure {
      &:after {
        background-color: #F08F8F;
      }
    }
  }
  &-yellow {
    figure {
      &:after {
        background-color: #ffcb2b;
      }
    }
  }
}

@supports(mix-blend-mode:multiply) {
  .overlay {
    figure {
      &:after {
        opacity: 1;
        mix-blend-mode: multiply;
      }
      img {
        filter: brightness(1) saturate(0) contrast(1.1);
        opacity: 0.8;
      }
    }
  }
}

.articles-slider {
  .carousel-cell {
    width: 100%;
  }
}