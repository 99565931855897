::selection {
  @apply text-white;
  @apply bg-black;
}

html {
  @apply h-auto;
  @apply min-h-full;
  @apply relative;
  @apply w-full;
  // remove 300ms delay on IE 10-11
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  scroll-behavior: smooth;
}

body {
  @apply w-screen;
  @apply min-h-full;
  @apply p-0;
  @apply m-0;
  @apply leading-tight;
  -webkit-font-smoothing: antialiased;
  @apply font-fg-light;
  font-size: 16px;
  letter-spacing: 0.05em;
  overflow-x: hidden;

  &.lock-screen {
    position: fixed;
  }
}

//No outlines
a,
input,
textarea,
button,
select {
  @apply outline-none;

  &:focus {
    @apply outline-none;
  }
}

a,
a:hover,
a:focus {
  @apply outline-none;
  @apply no-underline;
}

img {
  @apply w-full;
  @apply h-auto;
  @apply max-w-full;
  @apply m-0;
  @apply p-0;
  @apply border-0;
  @apply outline-none;
  @apply align-bottom;
}

.aspect-ratio {
  padding-bottom: 66%;
}

.aspect-ratio-70 {
  padding-bottom: 70%;
}

/*ASPECT RATIO FIX FOR IE AND EDGE*/

/*For IE*/
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .aspect-ratio {
    min-height: 200px;
  }

  .aspect-ratio-70 {
    min-height: 200px;
  }
}

/*For Edge 12-15*/
@supports (-ms-accelerator: true) {
  .aspect-ratio {
    min-height: 200px;
  }

  .aspect-ratio-70 {
    min-height: 200px;
  }
}

/*For Edge 16+*/
@supports (-ms-ime-align: auto) {
  .aspect-ratio {
    min-height: 200px;
  }

  .aspect-ratio-70 {
    min-height: 200px;
  }
}

.square {
  padding-bottom: 100%;
}

.chevron {
  transition: all 200ms;
}

.transition {
  transition: color 0ms, background-color 0ms linear, border-color 0ms linear, opacity 0ms linear, transform 0ms;
}

input::placeholder {
  color: black;
  opacity: 1;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 2px;
  overflow: hidden;
}

.slide-container {
  // scroll-behavior: smooth;
  // scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.slide-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.slide {
  scroll-snap-align: start;
}

.no-scroll-bars {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.no-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.equal-children {

  /* one item */
  @media (min-width: 768px) {
    .equal-child:first-child:nth-last-child(1) {
      /* -or- .equal-child:only-child { */
      width: 100%;
    }

    /* two items */
    .equal-child:first-child:nth-last-child(2),
    .equal-child:first-child:nth-last-child(2)~.equal-child {
      width: 50%;
    }

    /* three items */
    .equal-child:first-child:nth-last-child(3),
    .equal-child:first-child:nth-last-child(3)~.equal-child {
      width: 33.3333%;
    }

    /* four items */
    .equal-child:first-child:nth-last-child(4),
    .equal-child:first-child:nth-last-child(4)~.equal-child {
      width: 25%;

      @media (max-width: 1400px) {
        width: 50%;
      }
    }
  }
}

/*CATEGORY FILTER SCROLLBAR*/
/* width */
.category-filter::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.category-filter::-webkit-scrollbar-track {
  background-color: #2b2b2b;
  margin: 12px 0;
}

/* Handle */
.category-filter::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Handle on hover */
.category-filter::-webkit-scrollbar-thumb:hover {
  background-color: #dfdfe0;
}

.job-ad-feature-description {
  li {
    margin-bottom: 1rem !important;
  }
}

.scale-up-50 {
  transform: scale(1.5);
}

.portrait-overlay {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    bottom: 0;
    height: 100%;
    width: 50px;
    background: rgb(223, 223, 223);
    background: linear-gradient(90deg, rgb(223, 223, 223) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    height: 100%;
    width: 50px;
    background: rgb(223, 223, 223);
    background: linear-gradient(90deg, rgb(223, 223, 223) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.col-count-2 {
  @media (min-width: 768px) {
    column-count: 2;
  }
}

/*BACK TO TOP BUTTON*/

#back-to-top {
  opacity: 0;
  position: fixed;
  width: 48px;
  height: 48px;
  background-color: black;
  color: white;
  right: 36px;
  bottom: 36px;
  display: flex;
  z-index: -1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 200ms, transform 200ms;

  &:hover {
    transform: translateY(-3px);
  }
}

#typeAnimation:focus::-webkit-input-placeholder {
  color: transparent;
}

.flickity-viewport {
  transition: height 0.2s;
}


.search-wrapper {
  position: relative;

  input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath fill='%23000000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }

  .remove-button {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath fill='%23000000' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.border-fix {
  break-inside: avoid;
  page-break-inside: avoid;
  will-change: transform;
}

.z-highest {
  z-index: 999999;
}

.popup {
  pointer-events: none;
  visibility: hidden;

  &.popup-open {
    pointer-events: auto;
    visibility: visible;
  }
}

.popup-bg {
  transition: opacity 0.4s linear;
  opacity: 0;

  .popup-open & {
    opacity: 0.75;
  }
}

.popup-content {
  transition: opacity 0.3s linear 0.2s, transform 0.3s ease-in 0.2s;
  opacity: 0;
  transform: translateY(30);

  .popup-open & {
    opacity: 1;
    transform: translateY(0);
  }
}