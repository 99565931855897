.button {
  @apply inline-block;
  @apply bg-black;
  @apply border-black;
  @apply border;
  @apply text-white;
  @apply pt-5;
  @apply pb-5-5;
  @apply px-10;
  @apply mt-12;
  @apply mb-12;
  &:hover {
    @apply bg-transparent;
    @apply text-black;
    @apply border-black;
    @apply border;
  }
}
