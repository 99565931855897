.form {
  label {
    @apply block;
    @apply mb-3;
  }

  .required {
    .heading {
      label {
        &:after {
          content: "*";
        }
      }
    }
  }

  .errors {
    @apply text-red-600;
  }

  .radio-btn {
    padding-left: 26px;
  }
}