.wysiwyg {
  @apply text-xl;
  @apply tracking-slight;

  h1 {
    @apply text-5xl;
    @apply mb-5;
    @apply mt-5;
  }

  h2 {
    @apply text-3xl;
    @apply mt-12;
    @apply mb-4;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-2xl;
    @apply mt-12;
    @apply mb-5;
  }

  p,
  span {
    @apply text-lg;
    @apply leading-loose;
    @apply mb-4;
  }

  .ingress {
    @apply text-xl;
    @apply leading-slight;
    @apply mb-12;
  }

  figcaption {
    @apply text-lg;
    @apply mt-3;
  }

  ul,
  ol {
    @apply text-xl;
    @apply leading-loose;
    @apply my-6;

    li {
      @apply mb-4;
      @apply pl-6;
      @apply relative;

      .alumni-template & {
        margin-bottom: 6px;
        padding-left: 24px;
      }

      .event-single-template & {
        margin-bottom: 0;
        padding-left: 24px;
      }

      ul,
      ol {
        @apply mt-4;
        @apply mb-8;

        li {
          @apply pl-8;
          @apply mb-3;
        }
      }
    }
  }

  em {
    @apply font-fg-light-italic;
    font-weight: normal;
  }

  strong {
    @apply font-fg-medium;
    font-weight: normal;

    em {
      @apply font-fg-medium-italic;
      font-weight: normal;
    }
  }

  u {
    text-decoration: none;
    @apply text-xl;
    @apply leading-loose;
    @apply mb-4;
    @apply border-b;
  }

  ul {
    .alumni-template & {
      padding-left: 12px;
      margin-bottom: 1rem;
    }

    .event-single-template & {
      padding-left: 12px;
    }


    li::before {
      content: "";
      @apply block;
      @apply absolute;
      @apply rounded-full;
      @apply h-1;
      @apply w-1;
      @apply bg-black;
      left: 0;
      top: 15px;

      .event-single-template & {
        width: 6px;
        height: 6px;
      }
    }

    ul {
      li::before {
        @apply bg-transparent;
        @apply border;
        @apply border-black;
        left: 8px;
      }
    }
  }


  ol {
    counter-reset: section;

    li {

      &::before {
        content: counter(section)'.';
        counter-increment: section;
        display: block;
        position: absolute;
        left: 0;

        @media print {
          @apply bg-black;
        }
      }

      ol {
        list-style-type: lower-alpha;
        @apply pl-6;
        
        li {
          @apply pl-6;
          &::before {
            content: none;
          }
        }
      }
    }
  }



  blockquote {
    @apply text-2xl;
    @apply my-12;
    @apply leading-normal;
  }

  .author {
    @apply -mt-6;
    @apply mb-12;
  }


  table {
    @apply block;
    @apply overflow-x-scroll;
    @apply text-left;
    @apply w-full;
    @apply mb-12;

    thead {
      @apply bg-black;
      @apply text-white;
    }

    tr {
      @apply border-b;
      @apply border-black;
      @apply w-full;
    }

    th,
    td {
      @apply p-4;
      @apply min-w-60;
      vertical-align: top;
      font-weight: normal;
    }


  }

  a {
    @apply text-xl;
    @apply leading-loose;
    @apply mb-4;
    @apply border-b;

    &:hover:not(.button) {
      @apply border-none;
    }
  }

  figure {
    @apply my-12;
  }
}

@media only screen and (min-width: 768px) {
  .wysiwyg {

    h1 {
      @apply text-6xl;
      @apply mb-10;
      @apply mt-10;
    }

    h2 {
      @apply text-4xl;
      @apply mt-16;
      @apply mb-5;

      .event-single-template & {
        @apply text-6xl;
      }
    }

    h3,
    h4,
    h5,
    h6 {
      @apply text-3xl;
      @apply mt-16;
      @apply mb-5;
    }

    p,
    span {
      @apply text-xl;
    }

    .ingress {
      @apply text-2xl;
    }

    figcaption {
      @apply text-xl;
    }

    ul,
    ol {
      @apply my-8;
      
      li {
        @apply pl-8;

        .alumni-template & {
          margin-bottom: 6px;
          padding-left: 24px;
        }

        ul {
          li {
            @apply pl-12;
          }
        }
      }
    }

    ul {
      ul {
        li::before {
          left: 20px;
        }
      }
    }

    blockquote {
      @apply text-4xl;
      @apply my-16;
    }

    .author {
      @apply -mt-10;
      @apply mb-16;
    }

    figure {
      @apply my-16;
    }
  }
}

.small-wysiwyg {
  a {
    @apply border-b;

    &:hover {
      @apply border-none;
    }
  }
}

.links-underline {
  a {
    @apply leading-loose;
    @apply mb-4;
    @apply border-b;

    &:hover {
      @apply border-none;
    }
  }
}

.billing-information a {
  border: none;

  &:hover {
    border-bottom: 1px solid black;
  }
}