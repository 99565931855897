.rotate {
  transform: rotate(90deg);
  transform-origin: center;
}

.rotate-180 {
  transform: rotate(180deg);
}

.accordion-plus {
  transition: transform 200ms;
}

.cell {
  &__container {
    position: relative;
  }

  .arrow-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    .arrow-icon {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .arrow-icon {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 768px) {
    .arrow-icon {
      position: absolute;
      bottom: 0;
    }
  }
}