.cardLoader {
  @apply text-transparent;
  &__image {
    background-color: #eeeeee;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 20%, #eeeeee 40%);
    animation: image-loading 2s linear infinite;
    background-size: 800px auto;
  }
  [class*="line"] {
    animation: loading 2s linear infinite;
    background: linear-gradient(to right, #eee 8%, #ddd 16%, #eee 30%);
    background-size: 800px auto;
  }
  .line-micro {
    width: 50px;
    height: 12px;
    background-color: #eeeeee;
  }
  .line-small {
    width: 100px;
    // height: 15.3px;
    background-color: #eeeeee;
  }
  .line-medium {
    width: 200px;
    // height: 15.3px;
    background-color: #eeeeee;
  }
  .line-medium-large {
    width: 100%;
    max-width: 500px;
    // height: 15.3px;
    background-color: #eeeeee;
  }
  .line-large {
    width: 100%;
    // height: 34px;
    background-color: #eeeeee;
  }
  .line-extra-large {
    width: 350px;
    // height: 38px;
    background-color: #eeeeee;
  }
  .line-blue-dark {
    background-color: #0e274d;
    animation: loading 2s linear infinite;
    background: linear-gradient(to right, #1c3d5a 8%, #12283a 16%, #1c3d5a 30%);
    background-size: 800px auto;
  }
}

@keyframes image-loading {
  0% {
    background-position: -800px;
  }
  100% {
    background-position: 800px;
  }
}

@keyframes loading {
  0% {
    background-position: -800px;
  }
  100% {
    background-position: 800px;
  }
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.5s;
}
.fadeIn-enter, .fadeIn-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Loader 1 */
.loader-animation {
  height: 32px;
  width: 32px;
  animation: loader-1-1 4.8s linear infinite;
  span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    animation: loader-1-2 1.2s linear infinite;
    &:after {
      content: "";
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      clip: rect(0, 32px, 32px, 16px);
      border: 2px solid #000000;
      border-radius: 50%;
      animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }
  }
}
@keyframes loader-1-1 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes loader-1-2 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
}
@keyframes loader-1-3 {
  0%   { transform: rotate(-140deg); }
  50%  { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
}

.dot-loader:after {
  content: ' .';
  animation: dots 1.2s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;
  }
}
